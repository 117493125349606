body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root,
#root > div {
  overflow-x: hidden;
}

@font-face {
  font-family: "Autography";
  src: local("Autography"),
    url(./assets/fonts/Autography.otf) format("opentype");
}

@font-face {
  font-family: "baskervville";
  src: local("baskervville"),
    url(./assets/fonts/baskervville.regular.ttf) format("truetype");
}

@font-face {
  font-family: "poppins";
  src: local("poppins"), url(./assets/fonts/poppins.thin.ttf) format("truetype");
}
